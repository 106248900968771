import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const ASpecyalRemedyEP = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--40">
              <div className="sticky">

            <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

            <p className={style.albumDetails__credits}>
              <strong>Executive Producer:</strong> Specyal T<br/>
              <strong>Label:</strong> Independent<br/>
              <strong>Recorded At:</strong> Avenue Kode Studios<br/>
              <strong>Production Provided By:</strong> Foley, Mixtape Seoul & Layird&nbsp;Music <br/>
              <strong>Mixed & Mastered By:</strong> Felix Khan 
            </p>
            
            <div className={Contentstyle.subtitleLowercase}>Tracks</div>

            <ol className={style.albumDetails__tracks}>
              <li>Box Food</li>
              <li>Yorkshire Pudding</li>
              <li>Three</li>
              <li>Even Tho</li>
              <li>Your Place</li>
              <li>I’m Good</li>
              <li>Last Supper</li>
              <li>Peanut Butta Jealous ft. Jayde Wisdom</li>
              <li>Remind Me</li>
              <li>Already Done (Part 2)</li>
            </ol>
              </div>

            </div>
            <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>

            <p><strong>Box Food</strong><br/>
            Enjoying some quality alone time with your significant other. No distractions, just you and them connecting over some good food.</p>
            <hr/>
            <p><strong>Yorkshire Pudding</strong><br/>
            Getting distracted by an attractive stranger, while all your delicious thoughts tease your other senses simultaneously.</p>
            <hr/>
            <p><strong>Three</strong><br/>
            hose three little very important words we all want to hear. Sometimes it doesn’t go as planned and we can over think the moment, but when it arrives it’s one we’ll never forget.</p>
            <hr/>
            <p><strong>Even Tho</strong><br/>
            A musical love letter from one partner to the other, after their last visit together. While dealing with the many ups and downs of a long distance relationship.</p>
            <hr/>
            <p><strong>Your Place</strong><br/>
            Growing a relationship takes work. Being open and honest is essential for things moving forward. When one partner chooses to keep things hidden, this can cause tension and confusion for both. The truth is freeing.</p>
            <hr/>
            <p><strong>I’m Good</strong><br/>
            Dealing with the physical cravings and expectations of love when you’re in a new relationship. Trying to figure out that right moment to take things to the next level respectfully, when you’re both at potentially different stages.</p>
            <hr/>
            <p><strong>Last Supper</strong><br/>
            Exploring the harsh reality of the strain of many years of emotional and mental abuse, brought on by fear, resentment and betrayal. How one woman’s life so quickly unravels that she sinks into deep psychosis, where everything becomes disjointed and ultimately ends in tragedy.</p>
            <hr/>
            <p><strong>Peanut Butta Jealous</strong><br/>
            A sisterly bond between two best friends. One damaging secret has the ability to tear them apart forever. Then an unlikely new duo helps them both to realize there’s more to life than some temporary envy.</p>
            <hr/>
            <p><strong>Remind Me</strong><br/>
            Celebrating all the many wonderful moments in one’s relationship. The reasons you’re proud to be with them and have them by your side. #CoupleGoals</p>
            <hr/>
            <p><strong>Already Done (Part 2)</strong><br/>
            The conclusion to Already Done where one woman’s crime of passion ultimately ruins a number of lives. When we last left off, her friend was trying to persuade her to own up to her choices but she refused, leaving her friend to make the choice for everyone which landed them both in hot water. The conclusion sees the woman own up to her mistakes, release her friend of any involuntary involvement, and finally show remorse as she settles into her new life.</p>

           </div>

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default ASpecyalRemedyEP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/a_sweet_remedy.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`